import { default as _91_46_46_46slug_93Gstb2h5Cn3Meta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/[...slug].vue?macro=true";
import { default as createwTzWkgUSGuMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/booking-code-triggers/create.vue?macro=true";
import { default as _91id_93kHAYjFSl7cMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/booking-code-triggers/edit/[id].vue?macro=true";
import { default as indexKJ8AmH0yxNMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/booking-code-triggers/index.vue?macro=true";
import { default as createlgrpqOVNOTMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/brands/create.vue?macro=true";
import { default as _91id_93bTzZun8vl7Meta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/brands/edit/[id].vue?macro=true";
import { default as indexzsGfW246ACMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/brands/index.vue?macro=true";
import { default as indexVC5QyA18V3Meta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/caches/index.vue?macro=true";
import { default as createLJ5M7BokfbMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/menus/create.vue?macro=true";
import { default as _91id_93xOL11hXgKhMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/menus/edit/[id].vue?macro=true";
import { default as indexP0jqxdApcjMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/menus/index.vue?macro=true";
import { default as createcEfxFFvxrIMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/posts/create.vue?macro=true";
import { default as _91id_93woCAsgXLv3Meta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/posts/edit/[id].vue?macro=true";
import { default as indexFWJk248AkzMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/posts/index.vue?macro=true";
import { default as createMvEOvY1UrrMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/resorts/create.vue?macro=true";
import { default as _91id_93o3mSzZov2LMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/resorts/edit/[id].vue?macro=true";
import { default as indexWDAPw3O8B6Meta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/resorts/index.vue?macro=true";
import { default as _91id_93cWCgKBNxNqMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/specials/copy/[id].vue?macro=true";
import { default as createoRecMyuK6tMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/specials/create.vue?macro=true";
import { default as _91id_93X8niW1zr31Meta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/specials/edit/[id].vue?macro=true";
import { default as indexk1sXdOY149Meta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/specials/index.vue?macro=true";
import { default as index3mOw2SYdizMeta } from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/style-guide/index.vue?macro=true";
import { default as voix_45debug1PrXjgPgBUMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix-debug.vue?macro=true";
import { default as PreviewEntryl40u7WqbZqMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/PreviewEntry.vue?macro=true";
import { default as IndexglZ7GDYpJ5Meta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/settings-menu/Index.vue?macro=true";
import { default as loginNm4XmRehZCMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/login.vue?macro=true";
import { default as callbackh0ZXEhqEotMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/callback.vue?macro=true";
import { default as studio4AoHEf7sBwMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/studio.vue?macro=true";
import { default as IndexLfrdAU9uB6Meta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Index.vue?macro=true";
import { default as EditsTBC6aLSlYMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Edit.vue?macro=true";
import { default as Create2ucrEKszdIMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Create.vue?macro=true";
import { default as Index1MGJC7KkufMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Index.vue?macro=true";
import { default as CreateEEhzHQSJnnMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Create.vue?macro=true";
import { default as TranslatedYv65ogPsoMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Translate.vue?macro=true";
import { default as CopyErUyJZrxWZMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Copy.vue?macro=true";
import { default as IndexJpRDkuztvvMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Index.vue?macro=true";
import { default as IndexlS9OCK6ymHMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Index.vue?macro=true";
import { default as Editi0KvdrrMgLMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Edit.vue?macro=true";
import { default as CreateZZt9ABWYAZMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Create.vue?macro=true";
import { default as IndexCl06uA0iggMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Index.vue?macro=true";
import { default as CreatenIO1TkmDWaMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Create.vue?macro=true";
import { default as Edit6cEYjgUusBMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Edit.vue?macro=true";
import { default as Profile7auXUoe6YwMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Profile.vue?macro=true";
import { default as indexlhfIhLTU13Meta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/index.vue?macro=true";
import { default as SlicesView6vCTXCeVypMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/SlicesView.vue?macro=true";
import { default as ModelsViewqoEfUQKQjZMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/ModelsView.vue?macro=true";
import { default as TemplateViewBkbhfNXakbMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/TemplateView.vue?macro=true";
import { default as DesignGuideViewGqQ01POlqkMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/DesignGuideView.vue?macro=true";
import { default as templatizervKBUZJya8RMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/templatizer.vue?macro=true";
import { default as voixQW6E8hk1RdMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix.vue?macro=true";
import { default as SlicePreview10GeapjZeRMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/SlicePreview.vue?macro=true";
import { default as previewxeAEU6oUbcMeta } from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/preview.vue?macro=true";
export default [
  {
    name: "slug-en",
    path: "/:slug(.*)*",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug-es",
    path: "/es/:slug(.*)*",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "voix-booking-code-triggers-create-en",
    path: "/voix/booking-code-triggers/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/booking-code-triggers/create.vue").then(m => m.default || m)
  },
  {
    name: "voix-booking-code-triggers-create-es",
    path: "/es/voix/booking-code-triggers/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/booking-code-triggers/create.vue").then(m => m.default || m)
  },
  {
    name: "voix-booking-code-triggers-edit-id-en",
    path: "/voix/booking-code-triggers/edit/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/booking-code-triggers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-booking-code-triggers-edit-id-es",
    path: "/es/voix/booking-code-triggers/edit/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/booking-code-triggers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-booking-code-triggers-en",
    path: "/voix/booking-code-triggers",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/booking-code-triggers/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-booking-code-triggers-es",
    path: "/es/voix/booking-code-triggers",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/booking-code-triggers/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-brands-create-en",
    path: "/voix/brands/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/brands/create.vue").then(m => m.default || m)
  },
  {
    name: "voix-brands-create-es",
    path: "/es/voix/brands/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/brands/create.vue").then(m => m.default || m)
  },
  {
    name: "voix-brands-edit-id-en",
    path: "/voix/brands/edit/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/brands/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-brands-edit-id-es",
    path: "/es/voix/brands/edit/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/brands/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-brands-en",
    path: "/voix/brands",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-brands-es",
    path: "/es/voix/brands",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-caches-en",
    path: "/voix/caches",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/caches/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-caches-es",
    path: "/es/voix/caches",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/caches/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-menus-create-en",
    path: "/voix/menus/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/menus/create.vue").then(m => m.default || m)
  },
  {
    name: "voix-menus-create-es",
    path: "/es/voix/menus/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/menus/create.vue").then(m => m.default || m)
  },
  {
    name: "voix-menus-edit-id-en",
    path: "/voix/menus/edit/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/menus/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-menus-edit-id-es",
    path: "/es/voix/menus/edit/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/menus/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-menus-en",
    path: "/voix/menus",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/menus/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-menus-es",
    path: "/es/voix/menus",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/menus/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-posts-create-en",
    path: "/voix/posts/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/posts/create.vue").then(m => m.default || m)
  },
  {
    name: "voix-posts-create-es",
    path: "/es/voix/posts/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/posts/create.vue").then(m => m.default || m)
  },
  {
    name: "voix-posts-edit-id-en",
    path: "/voix/posts/edit/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/posts/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-posts-edit-id-es",
    path: "/es/voix/posts/edit/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/posts/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-posts-en",
    path: "/voix/posts",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-posts-es",
    path: "/es/voix/posts",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-resorts-create-en",
    path: "/voix/resorts/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/resorts/create.vue").then(m => m.default || m)
  },
  {
    name: "voix-resorts-create-es",
    path: "/es/voix/resorts/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/resorts/create.vue").then(m => m.default || m)
  },
  {
    name: "voix-resorts-edit-id-en",
    path: "/voix/resorts/edit/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/resorts/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-resorts-edit-id-es",
    path: "/es/voix/resorts/edit/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/resorts/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-resorts-en",
    path: "/voix/resorts",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/resorts/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-resorts-es",
    path: "/es/voix/resorts",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/resorts/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-specials-copy-id-en",
    path: "/voix/specials/copy/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/specials/copy/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-specials-copy-id-es",
    path: "/es/voix/specials/copy/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/specials/copy/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-specials-create-en",
    path: "/voix/specials/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/specials/create.vue").then(m => m.default || m)
  },
  {
    name: "voix-specials-create-es",
    path: "/es/voix/specials/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/specials/create.vue").then(m => m.default || m)
  },
  {
    name: "voix-specials-edit-id-en",
    path: "/voix/specials/edit/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/specials/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-specials-edit-id-es",
    path: "/es/voix/specials/edit/:id()",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/specials/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "voix-specials-en",
    path: "/voix/specials",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/specials/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-specials-es",
    path: "/es/voix/specials",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/specials/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-style-guide-en",
    path: "/voix/style-guide",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/style-guide/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-style-guide-es",
    path: "/es/voix/style-guide",
    component: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/pages/voix/style-guide/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-debug",
    path: "/voix-debug",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix-debug.vue").then(m => m.default || m)
  },
  {
    name: "voix-preview-release-entry-en",
    path: "/preview-release/:releaseId/:previewSecret",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/PreviewEntry.vue").then(m => m.default || m)
  },
  {
    name: "voix",
    path: "/voix",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix.vue").then(m => m.default || m),
    children: [
  {
    name: "voix-settings-en",
    path: "",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/settings-menu/Index.vue").then(m => m.default || m)
  },
  {
    name: "voix-login-en",
    path: "login",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "voix-auth-callback-en",
    path: "auth/callback",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: "voix-studio-en",
    path: "studio",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/studio.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-head-tags-en",
    path: "head-tags",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Index.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-head-tags-edit-en",
    path: "head-tags/:id",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Edit.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-head-tags-create-en",
    path: "head-tags/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Create.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-pages-en",
    path: "pages",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Index.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-pages-create-en",
    path: "pages/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Create.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-pages-translate-en",
    path: "pages/translate",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Translate.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-pages-copy-en",
    path: "pages/copy",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Copy.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-releases-en",
    path: "releases",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Index.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-redirects-en",
    path: "redirects",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Index.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-redirects-edit-en",
    path: "redirects/:id",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Edit.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-redirects-create-en",
    path: "redirects/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Create.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-users-en",
    path: "users",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Index.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-users-create-en",
    path: "users/create",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Create.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-users-edit-en",
    path: "users/:id",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Edit.vue").then(m => m.default || m)
  },
  {
    name: "voix-settings-users-profile-en",
    path: "profile",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Profile.vue").then(m => m.default || m)
  },
  {
    name: "voix-cookbook-en",
    path: "cookbook",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/index.vue").then(m => m.default || m)
  },
  {
    name: "voix-templatizer-en",
    path: "templatizer",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/templatizer.vue").then(m => m.default || m),
    children: [
  {
    name: "templatizer-slices-en",
    path: "",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/SlicesView.vue").then(m => m.default || m)
  },
  {
    name: "templatizer-models-en",
    path: "models",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/ModelsView.vue").then(m => m.default || m)
  },
  {
    name: "templatizer-template-en",
    path: "templates/:templateName",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/TemplateView.vue").then(m => m.default || m)
  },
  {
    name: "templatizer-design-guide-en",
    path: "design-guide",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/DesignGuideView.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "templatizer-slice-en",
    path: "/voix/slice-preview/:sliceName",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/SlicePreview.vue").then(m => m.default || m)
  },
  {
    name: "cookbook-preview-en",
    path: "/voix/cookbook-preview/:recipe",
    component: () => import("/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/preview.vue").then(m => m.default || m)
  }
]